import React, { Component } from 'react';
import '../../styles/Version1.css';


class Footer extends Component {
    render() {
        return (
            <div className='footer'>
                <p>&copy; 2019 Kevin Hu, All Rights Reserved</p>
            </div>
        )
    }
}

export default Footer;